import { throwError as observableThrowError, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { map, catchError } from 'rxjs/operators';
import { AirtableService } from '../airtable/airtable.service';
import { LoadingService } from '../loading/loading.service';
import { ToastrService } from 'ngx-toastr';
import { WindowRefService } from '../window-ref/window-ref.service';
var AuthService = /** @class */ (function () {
    function AuthService(airtableService, http, loadingService, router, toastr, windowRefService) {
        this.airtableService = airtableService;
        this.http = http;
        this.loadingService = loadingService;
        this.router = router;
        this.toastr = toastr;
        this.windowRefService = windowRefService;
        this.loginErrorSubject = new Subject();
        this.isLoggedInSubject = new Subject();
        this.resetRequestedSubject = new Subject();
        this.isLoggedIn$ = this.isLoggedInSubject.asObservable();
        this.loginError$ = this.loginErrorSubject.asObservable();
        this.resetRequested$ = this.resetRequestedSubject.asObservable();
        this._window = this.windowRefService.getNativeWindow();
        this.auth0 = new auth0.WebAuth({
            clientID: environment.AUTH0_CONFIG.clientID,
            domain: environment.AUTH0_CONFIG.domain,
            responseType: 'token id_token',
            audience: "https://" + environment.AUTH0_CONFIG.domain + "/userinfo",
            redirectUri: environment.AUTH0_CONFIG.baseURL + "/" + environment.AUTH0_CONFIG.callbackPath,
            scope: 'email'
        });
    }
    AuthService.prototype.signUp = function (userData) {
        return Promise.resolve(this.auth0.signup({
            email: userData.email,
            password: userData.password,
            connection: 'Username-Password-Authentication',
            user_metadata: {
                airtableId: userData.id,
                username: userData.username
            }
        }, (function (err) {
            if (!environment.production && err) {
                console.log(err);
            }
        })));
    };
    AuthService.prototype.login = function (formData) {
        var _this = this;
        this.auth0.login({
            email: formData.email,
            password: formData.password,
            realm: 'Username-Password-Authentication'
        }, (function (err) {
            _this.loginErrorSubject.next(err);
        }));
    };
    AuthService.prototype.logout = function () {
        this._window.localStorage.removeItem('access_token');
        this._window.localStorage.removeItem('id_token');
        this._window.localStorage.removeItem('expires_at');
        this._window.localStorage.removeItem('sh_u');
        this.auth0.logout();
    };
    AuthService.prototype.handleAuthentication = function () {
        var _this = this;
        this.loadingService.isLoading({ bool: true, type: 'login' });
        this.auth0.parseHash(function (err, authResult) {
            if (authResult && authResult.accessToken && authResult.idToken) {
                authResult.userMetadata = {
                    airtableId: authResult.idTokenPayload[environment.AUTH0_CONFIG.baseURL + "/user_metadata"].airtableId
                };
                _this.setSession(authResult);
            }
            else if (err) {
                if (!environment.production) {
                    console.log(err);
                }
                _this.router.navigate(['/login'])
                    .then(function () { return _this.loadingService.isLoading({ bool: false, type: 'login' }); });
            }
        });
    };
    AuthService.prototype.setSession = function (authResult) {
        var _this = this;
        this.airtableService.get("Business Info", authResult.userMetadata.airtableId)
            .then(function (data) {
            var expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
            var userData = JSON.stringify(_this.formatUserData(data));
            _this._window.localStorage.setItem('access_token', authResult.accessToken);
            _this._window.localStorage.setItem('id_token', authResult.idToken);
            _this._window.localStorage.setItem('expires_at', expiresAt);
            _this._window.localStorage.setItem('sh_u', userData);
            _this.router.navigate(['/dashboard'])
                .then(function () { return _this.loadingService.isLoading({ bool: false, type: 'login' }); });
        });
    };
    AuthService.prototype.setSessionToken = function (token) {
        this._window.sessionStorage.setItem('seshid', token);
    };
    AuthService.prototype.getSession = function () {
        var user = this._window.localStorage.getItem('sh_u');
        if (user) {
            return JSON.parse(user);
        }
    };
    AuthService.prototype.createCustomerSession = function (userData) {
        if (userData && userData.fields) {
            var customer = {
                customer: userData.fields.stripe_customer_id ? userData.fields.stripe_customer_id[0] : null
            };
            this._window.localStorage.setItem('sh_u', JSON.stringify(customer));
        }
    };
    AuthService.prototype.updateCustomerSession = function (userData) {
        var user = this._window.localStorage.getItem('sh_u');
        if (user) {
            var customer = JSON.parse(user);
            customer.card = userData.fields.cards ? userData.fields.cards[0] : null;
            customer.customer = userData.fields.stripe_customer_id ? userData.fields.stripe_customer_id[0] : null;
            customer.orders = userData.fields.orders || null;
            customer.positions = userData.fields['Positions'] || null;
            this._window.localStorage.setItem('sh_u', JSON.stringify(customer));
        }
    };
    AuthService.prototype.isAuthenticated = function () {
        var expiresAt = JSON.parse(this._window.localStorage.getItem('expires_at'));
        var isSessionActive = new Date().getTime() < expiresAt;
        this.isLoggedInSubject.next(isSessionActive);
        return isSessionActive;
    };
    AuthService.prototype.resetPassword = function (formData) {
        var _this = this;
        this.auth0.changePassword({
            email: formData.email,
            connection: 'Username-Password-Authentication'
        }, function () {
            _this.toastr.success("If the account exists we'll send reset instructions to your email.");
            _this.resetRequestedSubject.next(true);
        });
    };
    AuthService.prototype.formatUserData = function (userData) {
        if (userData && userData.fields) {
            return {
                address1: userData.fields['Business Street Address 1'] || null,
                appVersion: userData.fields.app_version || null,
                assessmentId: userData.fields['Business Responses (v1.6)'][0] || null,
                businessName: userData.fields['Business Name'] || null,
                card: userData.fields.cards ? userData.fields.cards[0] : null,
                city: userData.fields['Business City'] || null,
                customer: userData.fields.stripe_customer_id ? userData.fields.stripe_customer_id[0] : null,
                decsription: userData.fields['Business Description'] || null,
                email: userData.fields['Business Email'] || null,
                firstName: userData.fields['Business Contact - First Name'] || null,
                id: userData.id || null,
                industry: userData.fields['Industry'] || null,
                jobs: userData.fields['Positions'] || null,
                lastName: userData.fields['Business Contact - Last Name'] || null,
                orders: userData.fields.orders || null,
                phone: userData.fields['Business Phone Number'] || null,
                positions: userData.fields['Positions'] || null,
                state: userData.fields['Business State'] || null,
                username: userData.fields['Business Username'] || null,
                zipcode: userData.fields['Business Zip Code'] || null,
                schedule: userData.fields.interview_schedule ? userData.fields.interview_schedule[0] : null
            };
        }
    };
    /**
     * Sends a POST request to the API
     *
     * @param {string} uri The resource uri
     * @param {any} [data=null] The payload object
     * @returns {*} Server response data
     * @memberof ApiService
     */
    AuthService.prototype.post = function (uri, data) {
        if (data === void 0) { data = null; }
        return this.call({ uri: uri, method: 'POST' }, data);
    };
    /**
     * Generates headers for server requests
     *
     * @returns {*} HttpHeaders containing a header object with Authorization
     * @memberof ApiService
     */
    AuthService.prototype.getHeaders = function () {
        var headerObj = {
            'Content-Type': 'application/json'
        };
        return new HttpHeaders(headerObj);
    };
    /**
     * Makes a call to the server with provided options and payload
     *
     * @param {*} opts An object containing uri and request method
     * @param {*} data An object containing the payload
     * @returns {Observable<any>} Server response (success or error) data
     * @memberof ApiService
     */
    AuthService.prototype.call = function (opts, body) {
        var url = "" + (environment.AUTH0_GEN_CONFIG.baseURL + opts.uri);
        var headers = this.getHeaders();
        var methodCall = {
            POST: this.http.post(url, body ? body : null, { headers: headers })
        };
        return methodCall[opts.method]
            .pipe(map(function (res) { return res; }), catchError(function (err) { return observableThrowError(err.error); }));
    };
    return AuthService;
}());
export { AuthService };
