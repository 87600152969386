import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable()
export class AssessmentService {

  smbAssessmentVersion: string;
  applicantAssessmentVersion: string;

  constructor(
    private http: HttpClient
  ) {
    this.smbAssessmentVersion = environment.ASSESSMENT_VERSION.smb;
    this.applicantAssessmentVersion = environment.ASSESSMENT_VERSION.applicant;
  }

  getAssessmentVersion(type: string): string {
    if (type === 'smb') {
      return `Version ${this.smbAssessmentVersion}`;
    } else if (type === 'applicant') {
     return `Version ${this.applicantAssessmentVersion}`;
    }
  }

  getAssessmentQuestions(type: string): Observable<any> {
    let fileName;

    if (type === 'smb') {
      fileName = `smb-assessment-v${this.smbAssessmentVersion}`
    } else if (type === 'applicant') {
      fileName = `applicant-assessment-v${this.applicantAssessmentVersion}`;
    }
    return this.http.get(`./assets/data/${fileName}.json`);
  }

  orderQuestions(questions: any[]): any[] {
    return questions.sort((a, b) => a['Question Order'] - b['Question Order']);
  }
}
