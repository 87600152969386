import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { AirtableService } from '../airtable/airtable.service';
import { Observable, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterviewService {

  constructor(
    private airtableService: AirtableService,
    private authService: AuthService
  ) {}

  getSchedule(scheduleId): Q.Promise<any> {
    return this.airtableService.get(`smb_interview_schedules`, scheduleId);
  }

  getAvailability(schedule): Observable<any> {
    const slots = schedule.fields.smb_availabilities.map((id, i) => {
      return this.airtableService.get(`smb_availabilities`, id)
        .then((data) => data);
    });
    return forkJoin(slots);
  }

  formatDays(days: any[]): any[] {
    return days.map((day) => {
      const slotObj = {
        createdTime: null,
        id: null,
        fields: {
          ID: null,
          created_at: null,
          day: day.fields.name,
          editing: false,
          end_time: null,
          end_meridiem: 'AM',
          selected: false,
          smb_interview_schedules: [],
          start_meridiem: 'AM',
          start_time: null,
        }
      };

      return Object.assign(day, slotObj);
    });
  }

  formatScheduleData(schedule: any): any {
    return {
      business_id: [this.authService.getSession().id],
      interview_length: schedule.fields.interview_length,
      send_company_email: schedule.fields.send_company_email
    }
  }

  formatSlotData(slot: any, scheduleId?: string): any {
    return {
      day: slot.fields.day,
      end_meridiem: slot.fields.end_meridiem,
      end_time: slot.fields.end_time,
      selected: slot.fields.selected,
      smb_interview_schedules: scheduleId ? [scheduleId] : slot.fields.smb_interview_schedules,
      start_meridiem: slot.fields.start_meridiem,
      start_time: slot.fields.start_time
    }
  }
}
