import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingPageComponent implements OnInit, AfterViewInit {

  isLoading: boolean;

  constructor() {}

  ngOnInit() {
    this.isLoading = true;
  }

  ngAfterViewInit(): void {
    this.isLoading = false;
  }
}
