<section id="main__app">
  <div class="app">
    <div class="row">
      <div class="col-md-12">
        <div class="row justify-content-center">
          <div class="col-md-8 col-sm-12">
            <div class="content__card-header">
              <div class="content__card-header-text">
                <h2>Hassle Free Hiring</h2>
                <h3>Let CertainHire find a great match.</h3>
              </div>
            </div>

            <div class="card">
                <span class="text-center" [class.overlay-loading-on-card]="isLoading" *ngIf="isLoading">
                  <div class="lds-ring light-blue"><div></div><div></div><div></div><div></div></div>
                </span>
                <style>.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; margin-bottom: 2rem;} .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }</style><div class='embed-container'><iframe src='https://player.vimeo.com/video/287301065' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>

                <button type="button" class="btn btn-primary btn-large btn-block" [routerLink]="[ '/onboarding', 1 ]">
                Click Here to Get Started
                </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
