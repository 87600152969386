import { OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from '../../providers/auth/auth.service';
import { HelperService } from '../../providers/helper/helper.service';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(authService, helperService, router) {
        var _this = this;
        this.authService = authService;
        this.helperService = helperService;
        this.router = router;
        this.authService.isLoggedIn$
            .subscribe(function (event) { return _this.isLoggedIn = event; });
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function () { return _this.isOnboarding = _this.helperService.checkForOnboardingPath(); });
    };
    HeaderComponent.prototype.toRoot = function () {
        return this.helperService.getRootUrl();
    };
    HeaderComponent.prototype.handleLogout = function () {
        this.authService.logout();
    };
    return HeaderComponent;
}());
export { HeaderComponent };
