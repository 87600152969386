<div class="row">
  <div class="loading" [class.blue]="loadingType === 'storage'">
    <div class="col-8 offset-2 col-md-4 offset-md-4 align-self-center">
      <div class="text-center">
        <ng-container *ngIf="loadingType === 'login'">
          <div class="loading__logo pulse">
            <img src="assets/images/certain-mark-large.png" />
          </div>
          <h1>Signing You In...</h1>
          <p>We're loading your matches.</p>
        </ng-container>

        <ng-container *ngIf="loadingType === 'storage'">
          <div class="mb-3">
            <img src="assets/images/logo@2x.png" width="200" height="40">
          </div>
          <h1>Looks like you're browsing in incognito mode!</h1>
          <p>
            Private browsing prevents CertainHire from using cookies to store information needed to use the service.
          </p>
        </ng-container>
      </div>
    </div>
  </div>
</div>
