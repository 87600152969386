import { Injectable } from '@angular/core';
import * as Airtable from 'airtable-node/lib/index';
import { Promise } from 'q';
import { AssessmentService } from '../assessment/assessment.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AirtableService {

  airtableOpts: any;

  constructor(
    private assessmentService: AssessmentService
  ) {
    this.airtableOpts = {
      apiKey: environment.AIRTABLE_CONFIG.apiKey,
      base: environment.AIRTABLE_CONFIG.base,
      view: environment.AIRTABLE_CONFIG.view
    };
  }


  /**
   * Sends a GET request to the API
   *
   * @param {string} table The Airtable table name The Airtable table name
   * @param {number} [id=null]
   * @returns {Promise<any>} Server response data
   * @memberof AirtableService
   */
  get(table: string, id: string=null, offset: string='', count: number=null): Promise<any> {
    this.airtableOpts.table = table;
    this.airtableOpts.offset = offset;
    this.airtableOpts.count = count;
    const airtable = new Airtable(this.airtableOpts, count);

    if (id) {
      return airtable.retrieve(id)
        .then((data) => {
          return data;
        });
    } else {
      return airtable.list()
        .then((data) => {
          return data;
        });
    }
  }


  /**
   * Sends a POST request to the API
   *
   * @param {string} table The Airtable table name
   * @param {*} formData
   * @returns {Promise<any>} Server response data
   * @memberof AirtableService
   */
  post(table: string, formData: any): Promise<any> {
    this.airtableOpts.table = table;
    const airtable = new Airtable(this.airtableOpts);

    return airtable.create(formData)
    .then((data) => {
      return data;
    });
  }


  /**
   * Sends a PATCH request to the API
   *
   * @param {string} table The Airtable table name
   * @param {string} id Unique ID for the table record
   * @param {*} fields Object containing key: field name, value: data
   * @returns {Promise<any>} Server response data
   * @memberof AirtableService
   */
  patch(table: string, id: string, fields: any): Promise<any> {
    this.airtableOpts.table = table;
    const airtable = new Airtable(this.airtableOpts);

    return airtable.update(fields, id)
      .then((data) => {
        return data;
      });
  }


  /**
   * Sends a DELETE request to the API
   *
   * @param {string} table The Airtable table name
   * @param {string} id Unique ID for the table record
   * @returns {Promise<any>} Server response data
   * @memberof AirtableService
   */
  delete(table: string, id: string): Promise<any> {
    this.airtableOpts.table = table;
    const airtable = new Airtable(this.airtableOpts);

    return airtable.delete(id)
      .then((data) => {
        return data;
      });
  }

  formatApplicantData(formData: any): any {
    // Format start/end dates
    formData.employment_history.forEach((exp) => {
      exp.start_date = `${exp.start_date.month}/${exp.start_date.day}/${exp.start_date.year}`;

      if (exp.end_date) {
        exp.end_date = `${exp.end_date.month}/${exp.end_date.day}/${exp.end_date.year}`;
      } else {
        exp.current_job = true;
        exp.end_date = null;
      }
    });

    // Temporarily using static values limited to 3 items
    const applicantExp1 = formData.employment_history[0];
    const applicantExp2 = formData.employment_history[1];
    const applicantExp3 = formData.employment_history[2];

    return {
      'Applicant First Name': formData.application.first_name,
      'Applicant Last Name': formData.application.last_name,
      'Position ID': [formData.application.position_id],
      'Applicant Platform Referrer': formData.referrer,
      'Applicant Email': formData.application.email,
      'Applicant Phone': formData.application.phone,
      'Applicant Street Address 1': formData.application.address,
      'Applicant Attachments': formData.application.attachments,
      'Applicant Exp 1: Co Name': applicantExp1.company_name || null,
      'Applicant Exp 1: Current Job': applicantExp1.current_job || null,
      'Applicant Exp 1: Start Date': applicantExp1.start_date || null,
      'Applicant Exp 1: End Date': applicantExp1.end_date || null,
      'Applicant Exp 1: Job Title': applicantExp1.job_title || null,
      'Applicant Exp 1: Reason Leaving': applicantExp1.reason_for_leaving || null,
      'Applicant Exp 1: Responsibilities': applicantExp1.responsibilities || null,
      'Applicant Exp 2: Co Name': applicantExp2 ? applicantExp2.company_name : null,
      'Applicant Exp 2: Current Job': applicantExp2 ? applicantExp2.current_job : null,
      'Applicant Exp 2: Start Date': applicantExp2 ? applicantExp2.start_date : null,
      'Applicant Exp 2: End Date': applicantExp2 ? applicantExp2.end_date : null,
      'Applicant Exp 2: Job Title': applicantExp2 ? applicantExp2.job_title : null,
      'Applicant Exp 2: Reason Leaving': applicantExp2 ? applicantExp2.reason_for_leaving : null,
      'Applicant Exp 2: Responsibilities': applicantExp2 ? applicantExp2.responsibilities : null,
      'Applicant Exp 3: Co Name': applicantExp3 ? applicantExp3.company_name : null,
      'Applicant Exp 3: Current Job': applicantExp3 ? applicantExp3.current_job : null,
      'Applicant Exp 3: Start Date': applicantExp3 ? applicantExp3.start_date : null,
      'Applicant Exp 3: End Date': applicantExp3 ? applicantExp3.end_date : null,
      'Applicant Exp 3: Job Title': applicantExp3 ? applicantExp3.job_title : null,
      'Applicant Exp 3: Reason Leaving': applicantExp3 ? applicantExp3.reason_for_leaving : null,
      'Applicant Exp 3: Responsibilities': applicantExp3 ? applicantExp3.responsibilities : null,
      app_version: environment.APP_VERSION
    }
  }

  formatSmbInfo(formData: any): any {
    return {
      'Business Contact - First Name': formData.infoGroup.first_name,
      'Business Contact - Last Name': formData.infoGroup.last_name,
      'Business Name': formData.infoGroup.business_name,
      'Business Email': formData.infoGroup.email,
      app_version: environment.APP_VERSION
    };
  }

  formatJobPost(formData: any, res: any): any {
    const obj = {
      'Business ID': [res.id],
      'MasterJD': [formData.locationGroup.position.id],
      'Position Job Title': formData.positionInfoGroup.posting_title,
      'Position Description': formData.positionInfoGroup.job_description,
      'Position Availability': formData.positionDetailsGroup.shift_type,
      'Position Type': formData.positionDetailsGroup.employment_type,
      'Position Comp Type': formData.positionDetailsGroup.compensation_type,
      'Position Comp (low)': formData.positionDetailsGroup.pay_range_min,
      'Position Comp (high)': formData.positionDetailsGroup.pay_range_max,
      'Position Benefits Detail': formData.positionDetailsGroup.benefits_provided,
      'Position Perks': formData.positionDetailsGroup.perks_details,
      'Position Management': formData.positionDetailsGroup.is_mgmt_position === 'Yes' ? true : false,
      'Position Experience Required': formData.positionDetailsGroup.exp_required === 'Yes' ? true : false,
      business_responses_v2: [res.fields['Business Responses (v1.6)'][0]],
      app_version: environment.APP_VERSION
    };

    formData.assessment_attr_rank.forEach((attribute, i) => {
      obj[`Match Ranking ${i + 1}`] = attribute.name;
    });

    return obj;
  }

  formatNewJobPost(formData: any): any {
    const obj = {
      'Business ID': [formData.businessId],
      'MasterJD': [formData.groupOne.position.id],
      'Position Job Title': formData.groupTwo.posting_title,
      'Position Description': formData.groupTwo.job_description,
      'Position Availability': formData.groupOne.shift_type,
      'Position Type': formData.groupOne.employment_type,
      'Position Comp Type': formData.groupOne.compensation_type,
      'Position Comp (low)': formData.groupOne.pay_range_min,
      'Position Comp (high)': formData.groupOne.pay_range_max,
      'Position Benefits Detail': formData.groupOne.benefits_provided,
      'Position Perks': formData.groupOne.perks_details,
      'Position Management': formData.groupOne.is_mgmt_position === 'Yes' ? true : false,
      'Position Experience Required': formData.groupOne.exp_required === 'Yes' ? true : false,
      business_responses_v2: [formData.business_responses_v2],
      app_version: environment.APP_VERSION
    };

    formData.assessment_attr_rank.forEach((attribute, i) => {
      obj[`Match Ranking ${i + 1}`] = attribute.name;
    });

    return obj;
  }

  formatAppAssessmentAnswers(answers: any[], res: any): any {
    const obj = Object.assign({}, ...answers);
    obj['Applicant ID'] = [res.id];
    obj['Business ID'] = [res.fields['Business ID'][0]];
    obj['Position ID'] = [res.fields['Position ID'][0]];
    obj['Assessment Version'] = this.assessmentService.getAssessmentVersion('applicant');
    obj.app_version = environment.APP_VERSION;
    return obj;
  }

  formatBizAssessmentAnswers(formData: any): any {
    const obj = Object.assign({}, ...formData.assessmentAnswers);
    obj['Business ID'] = [formData.id];
    obj['Assessment Version'] = this.assessmentService.getAssessmentVersion('smb');
    obj.app_version = environment.APP_VERSION;
    return obj;
  }
}
