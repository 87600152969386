import { ApiService } from '../../../shared/providers/api/api.service';
import { Subject } from 'rxjs';
var OnboardingService = /** @class */ (function () {
    function OnboardingService(apiService) {
        this.apiService = apiService;
        this.completeSubject = new Subject();
        this.complete$ = this.completeSubject.asObservable();
    }
    OnboardingService.prototype.isStepComplete = function (complete) {
        this.completeSubject.next(complete);
    };
    return OnboardingService;
}());
export { OnboardingService };
