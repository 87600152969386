import { OnInit, AfterViewInit } from '@angular/core';
var LandingPageComponent = /** @class */ (function () {
    function LandingPageComponent() {
    }
    LandingPageComponent.prototype.ngOnInit = function () {
        this.isLoading = true;
    };
    LandingPageComponent.prototype.ngAfterViewInit = function () {
        this.isLoading = false;
    };
    return LandingPageComponent;
}());
export { LandingPageComponent };
