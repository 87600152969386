import { OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { HelperService } from '../../providers/helper/helper.service';
var FooterComponent = /** @class */ (function () {
    function FooterComponent(helperService, router) {
        this.helperService = helperService;
        this.router = router;
    }
    FooterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function () { return _this.isOnboarding = _this.helperService.checkForOnboardingPath(); });
    };
    FooterComponent.prototype.toRoot = function () {
        return this.helperService.getRootUrl();
    };
    return FooterComponent;
}());
export { FooterComponent };
