import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { AirtableService } from '../../../../shared/providers/airtable/airtable.service';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class JobApplicationResolve implements Resolve<any> {
  constructor(
    private airtableService: AirtableService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!route.queryParams.job_id) {
      window.location.href = 'https://apply.certainhire.com';
    } else {
      return this.airtableService.get(`positions`, route.queryParams.job_id, '', null)
        .then((data) => {
          if (data.error) {
            if (data.error === 'NOT_FOUND' || data.error.type === 'MODEL_ID_NOT_FOUND') {
              this.router.navigate([ '/404' ]);
            } else if (!environment.production) {
              console.log(data.error);
            }
          } else {
            return data;
          }
        });
    }
  }
}
