import { Injectable } from '@angular/core';
import { WindowRefService } from '../window-ref/window-ref.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class TraitifyService {

  traitify: any;
  _window: any;

  constructor(
    private windowRefService: WindowRefService
  ) {
    this._window  = this.windowRefService.getNativeWindow();
    this.traitify = this._window.Traitify;
    this.traitify.setPublicKey(environment.TRAITIFY_CONFIG.publicKey);
    this.traitify.setHost(environment.TRAITIFY_CONFIG.host);
    this.traitify.setVersion(environment.TRAITIFY_CONFIG.version);
  }

  // TODO: Need to grab assessment id prior to calls

  getDecks(id): any {
    return this.traitify
      .getDecks(id)
      .then((data) => {
        console.log(data)
    });
  }

  getSlides(id): any {
    return this.traitify
      .getSlides(id)
      .then((data) => {
        console.log(data)
    });
  }

  addSlide(id): any {
    return this.traitify
      .addSlide(id)
      .then((data) => {
        console.log(data)
    });
  }

  getPersonalityTraits(id): any {
    return this.traitify
      .getPersonalityTraits(id)
      .then((data) => {
        console.log(data)
    });
  }

  getPersonalityTypes(id): any {
    return this.traitify
      .getPersonalityTypes(id)
      .then((data) => {
        console.log(data)
    });
  }

  getPersonalityBlend(id): any {
    return this.traitify
      .get(`/assessments/${id}?data=blend&image_pack=linear`)
      .then((data) => {
        console.log(data)
    });
  }

  getSlidesByUrl(id): any {
    return this.traitify
      .get(`/assessments/${id}/slides`)
      .then((data) => {
        console.log(data)
    })
  }
}
