import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

@Injectable()
export class HelperService {

  constructor(
    private location: Location
  ) {}

  getRootUrl(): string {
    if (environment.production) {
      return 'https://join.certainhire.com';
    } else if (environment.staging) {
      return 'https://staging.certainhire.com';
    } else {
      return 'http://localhost:4200';
    }
  }

  getCardImagePath(cardType: string): string {
    let cardTypeImgPath;

    switch (cardType) {
      case 'American Express':
        cardTypeImgPath = `assets/images/cc-american-express.svg`;
        break;
      case 'Discover':
        cardTypeImgPath = `assets/images/cc-discover.svg`;
        break;
      case 'MasterCard':
        cardTypeImgPath = `assets/images/cc-mastercard.svg`;
        break;
      case 'Visa':
        cardTypeImgPath = `assets/images/cc-visa.svg`;
        break;
      default:
        break;
    }

    return cardTypeImgPath;
  }

  checkForOnboardingPath(): boolean {
    if (this.location.path().includes('onboarding')) {
      return true;
    } else {
      return false;
    }
  }
}
