import { environment } from '../../../../environments/environment';
import { ErrorHandler } from '@angular/core';
import * as filestack from 'filestack-js';
var FilestackService = /** @class */ (function () {
    function FilestackService(errHandler) {
        this.errHandler = errHandler;
        this.apikey = environment.FILE_STACK_API_KEY;
        this.client = filestack.default.init(this.apikey);
    }
    FilestackService.prototype.upload = function () {
        var _this = this;
        return this.client.pick({
            maxFiles: 1,
            uploadInBackground: false,
            accept: ['.pdf', '.doc', '.docx', '.odt'],
            fromSources: ['local_file_system'],
            storeTo: {
                location: 's3',
                region: 'us-east-2',
                container: 'certainhire.uploads',
                access: 'private'
            }
        })
            .then(function (res) {
            if (res.filesUploaded) {
                return res.filesUploaded[0];
            }
            else {
                if (environment.production) {
                    _this.errHandler.handleError(JSON.stringify(res.filesFailed));
                }
            }
        });
    };
    return FilestackService;
}());
export { FilestackService };
