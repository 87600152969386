import { environment } from '../environments/environment';
import { OnInit } from '@angular/core';
import { AuthService } from './shared/providers/auth/auth.service';
import { LoadingService } from './shared/providers/loading/loading.service';
import { MixpanelService } from './shared/providers/mixpanel/mixpanel.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(authService, loadingService, mixpanelService) {
        var _this = this;
        this.authService = authService;
        this.loadingService = loadingService;
        this.mixpanelService = mixpanelService;
        this.loadingService.isLoading$
            .subscribe(function (data) { return _this.isLoading = data; });
    }
    AppComponent.prototype.ngOnInit = function () {
        this.mixpanelService.setMpSessionId();
        this.setSession();
    };
    AppComponent.prototype.setSession = function () {
        var _this = this;
        var data = {
            client_id: environment.AUTH0_GEN_CONFIG.cid,
            client_secret: environment.AUTH0_GEN_CONFIG.cs,
            audience: environment.AUTH0_GEN_CONFIG.audience,
            grant_type: environment.AUTH0_GEN_CONFIG.gt
        };
        this.authService.post("oauth/token", data)
            .subscribe(function (data) { return _this.authService.setSessionToken(data.access_token); });
    };
    return AppComponent;
}());
export { AppComponent };
