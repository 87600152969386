import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { AirtableService } from '../airtable/airtable.service';
import { AlertService } from '../alert/alert.service';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class InterviewPanelService {

  open$: Observable<any>;
  private openPanelSubject = new Subject<any>();

  constructor(
    private alertService: AlertService,
    private airtableService: AirtableService,
    private apiService: ApiService
  ) {
    this.open$ = this.openPanelSubject.asObservable();
  }

  openPanel(panel: any): void {
    this.openPanelSubject.next(panel);
  }

  closePanel(panel: any): void {
    panel.open = false;
    this.openPanelSubject.next(panel);
  }

  formatDates(slots): any {
    const formattedSlots = slots.filter((slot) => (slot.date && slot.time))
      .map((slot, i) => {
        if (slot.time[0] === '0') {
          const time = slot.time.replace(/^0+/, '');
          slot.time = `${time.slice(0, 1)}:${time.slice(1)}`;
        } else {
          slot.time = `${slot.time.slice(0, 2)}:${slot.time.slice(2)}`;
        }
        return `${i + 1}. ${slot.date.month}/${slot.date.day}/${slot.date.year} at ${slot.time} ${slot.meridiem}`
      });

    formattedSlots.push(`${formattedSlots.length + 1}. None of these work for me.`);
    return formattedSlots;
  }

  formatInterviewData(request): any {
    request.slots = this.formatDates(request.slots);
    const datetimeKeyTwo = request.slots[1] && request.slots[1].includes('None') ? 'datetime_none' : 'datetime_two';
    const datetimeKeyThree = request.slots[2] && request.slots[2].includes('None') ? 'datetime_none' : 'datetime_three';
    const datetimeKeyFour = request.slots[3] && request.slots[3].includes('None') ? 'datetime_none' : 'datetime_four';
    const candFirstName = request.interviewee.fields['Applicant First Name'] ? request.interviewee.fields['Applicant First Name'][0] : null;
    const candLastName = request.interviewee.fields['Applicant Last Name'] ? request.interviewee.fields['Applicant Last Name'][0] : null;
    const candPhone = request.interviewee.fields['Applicant Phone'] ? request.interviewee.fields['Applicant Phone'][0] : null;
    const candEmail = request.interviewee.fields['Applicant Email'] ? request.interviewee.fields['Applicant Email'][0] : null;
    const jobTitle = request.interviewee.fields['Position Job Title'] ? request.interviewee.fields['Position Job Title'][0] : null;

    return {
      message: request.message || null,
      datetime_one: request.slots[0] || null,
      [`${datetimeKeyTwo}`]: request.slots[1] || null,
      [`${datetimeKeyThree}`]: request.slots[2] || null,
      [`${datetimeKeyFour}`]: request.slots[3] || null,
      candidate_first_name: candFirstName,
      candidate_last_name: candLastName,
      candidate_phone: `+1${candPhone}`,
      candidate_email: candEmail,
      smb_first_name: request.smb.firstName,
      smb_last_name: request.smb.lastName,
      smb_business: request.smb.businessName,
      smb_phone: `+1${request.smb.phone}`,
      smb_email: request.smb.email,
      job_title: jobTitle
    };
  }

  onSchedule(panel): void {
    const applicantId = panel.interviewee.fields['Applicant ID'][0];
    const fields = { 'SMB Interview Request': true };
    const payload = this.formatInterviewData(panel);
    payload.flow = `FW3d9993ccab763ec61f6fdf3903837452`;

    this.apiService.post(`messages`, JSON.stringify(payload))
      .subscribe((data) => {
        this.airtableService.patch(`Applicant Info`, applicantId, fields)
          .then((data) => {
            if (data.error) {
              console.log(data.error);
            } else {
              this.closePanel(panel);
              panel.interviewee.fields['SMB Interview Request'][0] = true;
              this.alertService.showAlert({
                id: 1,
                type: 'success',
                msg: `We've received your request to schedule an interview with this match.
                Expect an email from our scheduling team within 24 hours.`
              });
            }
          });
      });
  }
}
