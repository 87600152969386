import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class AlertService {

  alert$: Observable<any>;
  private alertSubject = new Subject<any>();

  constructor() {
    this.alert$ = this.alertSubject.asObservable();
  }

  showAlert(alert: any): void {
    this.alertSubject.next(alert);
  }
}
