import { WindowRefService } from '../../../shared/providers/window-ref/window-ref.service';
var NewJobPostGuard = /** @class */ (function () {
    function NewJobPostGuard(windowRefService) {
        this.windowRefService = windowRefService;
        this._window = this.windowRefService.getNativeWindow();
    }
    NewJobPostGuard.prototype.canDeactivate = function (component) {
        if (component.isPaymentComplete && !component.submitted) {
            return this._window.confirm('Are you sure? Your data will be lost.');
        }
        return true;
    };
    return NewJobPostGuard;
}());
export { NewJobPostGuard };
