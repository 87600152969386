import { Injectable } from '@angular/core';

@Injectable()
export class JobService {

  constructor() {}

  getStepContent(index?: number): any {
    const stepContent = [
      {
        number: 1,
        title: `Apply at`,
        subTitle: `Let's get to know a little bit more about you.`,
        mpEvent: `application_launch`
      },
      {
        number: 2,
        title: `Apply at`,
        subTitle: `What relevant job experience do you want highlighted in your profile?`,
        mpEvent: `employment_history_load`
      },
      {
        number: 3,
        title: `Great! Let's answer a few more questions for this position at `,
        subTitle: `Let's take some time to get to know you.`,
        mpEvent: `assessment_instructions_load`
      },
      {
        number: 4,
        title: `Thanks for Applying!`,
        subTitle: `We've got all your information and we're looking at matching you to this gig.`,
        mpEvent: `assessment_start`
      },
      {
        number: 5,
        title: null,
        subTitle: null,
        mpEvent: `assessment_complete`
      }
    ];

    return index === undefined ? stepContent : stepContent.filter((content) => content.number === index)[0];
  }
}
