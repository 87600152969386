<section id="main__app">
  <div class="app">
    <div class="row">
      <div class="col-md-12">
        <div class="row justify-content-center">
          <div class="col-md-6 col-sm-12">
            <div class="card">
              <div class="not__found-wrapper text-center">
                <div class="not__found-logo">
                  <img src="assets/images/slick-mark.svg" />
                </div>
                <h1>404 Error</h1>
              </div>
              <p class=" text-center">
                Sorry, the page you are looking for could not be found.
              </p>

              <div class="col-md-4 offset-md-4 col-sm-12">
                <button type="button" class="btn btn-primary btn-large btn-block" (click)="toHome()">
                  Go to Home Page
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
