/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/components/loading/loading.component.ngfactory";
import * as i3 from "./shared/components/loading/loading.component";
import * as i4 from "@angular/common";
import * as i5 from "./shared/components/header/header.component.ngfactory";
import * as i6 from "./shared/components/header/header.component";
import * as i7 from "./shared/providers/auth/auth.service";
import * as i8 from "./shared/providers/helper/helper.service";
import * as i9 from "@angular/router";
import * as i10 from "./shared/components/footer/footer.component.ngfactory";
import * as i11 from "./shared/components/footer/footer.component";
import * as i12 from "./app.component";
import * as i13 from "./shared/providers/loading/loading.service";
import * as i14 from "./shared/providers/mixpanel/mixpanel.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading", [], null, null, null, i2.View_LoadingComponent_0, i2.RenderType_LoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoadingComponent, [], { loadingType: [0, "loadingType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.isLoading == null) ? null : _co.isLoading.type); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "ui-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-header", [], null, null, null, i5.View_HeaderComponent_0, i5.RenderType_HeaderComponent)), i1.ɵdid(4, 114688, null, 0, i6.HeaderComponent, [i7.AuthService, i8.HelperService, i9.Router], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "container-fluid container-ui"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-footer", [], null, null, null, i10.View_FooterComponent_0, i10.RenderType_FooterComponent)), i1.ɵdid(9, 114688, null, 0, i11.FooterComponent, [i8.HelperService, i9.Router], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.isLoading == null) ? null : _co.isLoading.bool); _ck(_v, 2, 0, currVal_0); _ck(_v, 4, 0); _ck(_v, 7, 0); _ck(_v, 9, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i12.AppComponent, [i7.AuthService, i13.LoadingService, i14.MixpanelService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i12.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
