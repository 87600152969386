import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/providers/api/api.service';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class OnboardingService {

  complete$: Observable<boolean>;
  private completeSubject = new Subject<any>();

  constructor(
    private apiService: ApiService
  ) {
    this.complete$ = this.completeSubject.asObservable();
  }

  isStepComplete(complete: boolean): void {
    this.completeSubject.next(complete);
  }
}
