import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

import { JobPostingsCreateComponent } from '../components/job-postings-create/job-postings-create.component';

import { WindowRefService } from '../../../shared/providers/window-ref/window-ref.service';

@Injectable()
export class NewJobPostGuard implements CanDeactivate<JobPostingsCreateComponent> {

  _window: Window;

  constructor(
    private windowRefService: WindowRefService
  ) {
    this._window = this.windowRefService.getNativeWindow();
  }

  canDeactivate(component: JobPostingsCreateComponent) {
    if (component.isPaymentComplete && !component.submitted) {
      return this._window.confirm('Are you sure? Your data will be lost.');
    }
    return true;
  }
}
