import { Routes } from '@angular/router';
import { StorageGuard } from './shared/guards/storage/storage.guard';
import { LandingPageComponent } from './pages/landing/landing.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
export var ROUTES = [
    { path: '',
        component: LandingPageComponent,
        pathMatch: 'full',
        canActivate: [StorageGuard]
    },
    {
        path: 'dashboard',
        loadChildren: './modules/dashboard/dashboard.module#DashboardModule',
        canActivate: [StorageGuard]
    },
    {
        path: 'jobs',
        loadChildren: './modules/jobs/jobs.module#JobsModule',
        canActivate: [StorageGuard]
    },
    {
        path: 'login',
        loadChildren: './modules/auth/auth.module#AuthModule',
        canActivate: [StorageGuard]
    },
    {
        path: 'onboarding',
        loadChildren: './modules/onboarding/onboarding.module#OnboardingModule',
        canActivate: [StorageGuard]
    },
    { path: '404', component: NotFoundComponent },
    { path: '**', component: NotFoundComponent }
];
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
