import { environment } from '../environments/environment';
import { Component, OnInit } from '@angular/core';

import { AuthService } from './shared/providers/auth/auth.service';
import { LoadingService } from './shared/providers/loading/loading.service';
import { MixpanelService } from './shared/providers/mixpanel/mixpanel.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  isLoading: any;

  constructor(
    private authService: AuthService,
    private loadingService: LoadingService,
    private mixpanelService: MixpanelService
  ) {
    this.loadingService.isLoading$
      .subscribe((data: Boolean) => this.isLoading = data);
  }

  ngOnInit() {
    this.mixpanelService.setMpSessionId();
    this.setSession();
  }

  setSession(): void {
    const data = {
      client_id: environment.AUTH0_GEN_CONFIG.cid,
      client_secret: environment.AUTH0_GEN_CONFIG.cs,
      audience: environment.AUTH0_GEN_CONFIG.audience,
      grant_type: environment.AUTH0_GEN_CONFIG.gt
    };

    this.authService.post(`oauth/token`, data)
      .subscribe((data) => this.authService.setSessionToken(data.access_token));
  }
}
