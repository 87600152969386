import { Router } from '@angular/router';
import { AuthService } from '../../providers/auth/auth.service';
import { WindowRefService } from '../../providers/window-ref/window-ref.service';
var AuthGuard = /** @class */ (function () {
    function AuthGuard(authService, router, windowRefService) {
        this.authService = authService;
        this.router = router;
        this.windowRefService = windowRefService;
        this._window = this.windowRefService.getNativeWindow();
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        var isLoggedIn = this.authService.isAuthenticated();
        if (!isLoggedIn) {
            if (this.authService.getSession()) {
                this._window.localStorage.removeItem('sh_u');
            }
            this.router.navigate(['/login']);
        }
        return isLoggedIn;
    };
    return AuthGuard;
}());
export { AuthGuard };
