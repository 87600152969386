function _window() {
    return window;
}
var WindowRefService = /** @class */ (function () {
    function WindowRefService() {
    }
    WindowRefService.prototype.getNativeWindow = function () {
        return _window();
    };
    return WindowRefService;
}());
export { WindowRefService };
