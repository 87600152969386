import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
var AssessmentService = /** @class */ (function () {
    function AssessmentService(http) {
        this.http = http;
        this.smbAssessmentVersion = environment.ASSESSMENT_VERSION.smb;
        this.applicantAssessmentVersion = environment.ASSESSMENT_VERSION.applicant;
    }
    AssessmentService.prototype.getAssessmentVersion = function (type) {
        if (type === 'smb') {
            return "Version " + this.smbAssessmentVersion;
        }
        else if (type === 'applicant') {
            return "Version " + this.applicantAssessmentVersion;
        }
    };
    AssessmentService.prototype.getAssessmentQuestions = function (type) {
        var fileName;
        if (type === 'smb') {
            fileName = "smb-assessment-v" + this.smbAssessmentVersion;
        }
        else if (type === 'applicant') {
            fileName = "applicant-assessment-v" + this.applicantAssessmentVersion;
        }
        return this.http.get("./assets/data/" + fileName + ".json");
    };
    AssessmentService.prototype.orderQuestions = function (questions) {
        return questions.sort(function (a, b) { return a['Question Order'] - b['Question Order']; });
    };
    return AssessmentService;
}());
export { AssessmentService };
