<div class="containeir-fluid footer">
  <footer class="footer-bottom">
    <ul class="list-inline">
      <li>
        <a [href]="toRoot()"><img src="assets/images/footer-logo@2x.png" width="119" height="25" /></a>
      </li>
      <li *ngIf="isOnboarding">
        <a [routerLink]="[ '/login' ]">Login</a> •
      </li>
      <li>
        <a href="https://www.certainhire.com/faq" target="_blank">FAQ</a> •
      </li>
      <li>
        <a href="mailto:info@certainhire.com">Contact Us</a> •
      </li>
      <li>
        <a href="https://www.certainhire.com/terms-of-use" target="_blank">Terms of Use</a> •
      </li>
      <li>
        <a href="https://www.certainhire.com/privacy" target="_blank">Privacy Policy</a>
      </li>
    </ul>
  </footer>
</div>
