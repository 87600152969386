import { environment } from '../../../../environments/environment';
import { Location } from '@angular/common';
var HelperService = /** @class */ (function () {
    function HelperService(location) {
        this.location = location;
    }
    HelperService.prototype.getRootUrl = function () {
        if (environment.production) {
            return 'https://join.certainhire.com';
        }
        else if (environment.staging) {
            return 'https://staging.certainhire.com';
        }
        else {
            return 'http://localhost:4200';
        }
    };
    HelperService.prototype.getCardImagePath = function (cardType) {
        var cardTypeImgPath;
        switch (cardType) {
            case 'American Express':
                cardTypeImgPath = "assets/images/cc-american-express.svg";
                break;
            case 'Discover':
                cardTypeImgPath = "assets/images/cc-discover.svg";
                break;
            case 'MasterCard':
                cardTypeImgPath = "assets/images/cc-mastercard.svg";
                break;
            case 'Visa':
                cardTypeImgPath = "assets/images/cc-visa.svg";
                break;
            default:
                break;
        }
        return cardTypeImgPath;
    };
    HelperService.prototype.checkForOnboardingPath = function () {
        if (this.location.path().includes('onboarding')) {
            return true;
        }
        else {
            return false;
        }
    };
    return HelperService;
}());
export { HelperService };
