<div class="row">
  <nav class="nav__ui-brand col-md-2">
    <div class="brand">
      <a [href]="toRoot()"><img src="assets/images/logo@2x.png" width="200" height="40"></a>
    </div>
  </nav>

  <div class="col-md-6 offset-md-4" *ngIf="!isOnboarding">
    <ul class="list-inline pull-right">
      <li *ngIf="isLoggedIn" routerLinkActive="active">
        <a [routerLink]="[ '/dashboard' ]">Dashboard</a>
      </li>
      <li *ngIf="!isLoggedIn" routerLinkActive="active">
        <a href="https://apply.certainhire.com">Job Seekers</a>
      </li>
      <li *ngIf="!isLoggedIn" routerLinkActive="active">
        <a [routerLink]="[ '/onboarding', 1 ]">Business Owners</a>
      </li>
      <li *ngIf="!isLoggedIn" routerLinkActive="active">
        <a [routerLink]="[ '/login' ]">Login</a>
      </li>
      <li *ngIf="isLoggedIn">
        <a (click)="handleLogout()">Logout</a>
      </li>
      <!-- <li>About Us</li> -->
      <li *ngIf="!isLoggedIn">
        <a href="mailto:info@certainhire.com">Contact Us</a>
      </li>
    </ul>
  </div>
</div>
