<div class="ui-wrapper">
  <app-loading *ngIf="isLoading?.bool" [loadingType]="isLoading?.type"></app-loading>
  <app-header></app-header>

  <div class="container-fluid container-ui">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>
</div>
