import { AuthService } from '../auth/auth.service';
import { AirtableService } from '../airtable/airtable.service';
import { forkJoin } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../airtable/airtable.service";
import * as i2 from "../auth/auth.service";
var InterviewService = /** @class */ (function () {
    function InterviewService(airtableService, authService) {
        this.airtableService = airtableService;
        this.authService = authService;
    }
    InterviewService.prototype.getSchedule = function (scheduleId) {
        return this.airtableService.get("smb_interview_schedules", scheduleId);
    };
    InterviewService.prototype.getAvailability = function (schedule) {
        var _this = this;
        var slots = schedule.fields.smb_availabilities.map(function (id, i) {
            return _this.airtableService.get("smb_availabilities", id)
                .then(function (data) { return data; });
        });
        return forkJoin(slots);
    };
    InterviewService.prototype.formatDays = function (days) {
        return days.map(function (day) {
            var slotObj = {
                createdTime: null,
                id: null,
                fields: {
                    ID: null,
                    created_at: null,
                    day: day.fields.name,
                    editing: false,
                    end_time: null,
                    end_meridiem: 'AM',
                    selected: false,
                    smb_interview_schedules: [],
                    start_meridiem: 'AM',
                    start_time: null,
                }
            };
            return Object.assign(day, slotObj);
        });
    };
    InterviewService.prototype.formatScheduleData = function (schedule) {
        return {
            business_id: [this.authService.getSession().id],
            interview_length: schedule.fields.interview_length,
            send_company_email: schedule.fields.send_company_email
        };
    };
    InterviewService.prototype.formatSlotData = function (slot, scheduleId) {
        return {
            day: slot.fields.day,
            end_meridiem: slot.fields.end_meridiem,
            end_time: slot.fields.end_time,
            selected: slot.fields.selected,
            smb_interview_schedules: scheduleId ? [scheduleId] : slot.fields.smb_interview_schedules,
            start_meridiem: slot.fields.start_meridiem,
            start_time: slot.fields.start_time
        };
    };
    InterviewService.ngInjectableDef = i0.defineInjectable({ factory: function InterviewService_Factory() { return new InterviewService(i0.inject(i1.AirtableService), i0.inject(i2.AuthService)); }, token: InterviewService, providedIn: "root" });
    return InterviewService;
}());
export { InterviewService };
