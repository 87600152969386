import { Subject } from 'rxjs';
import { AirtableService } from '../airtable/airtable.service';
import { AlertService } from '../alert/alert.service';
import { ApiService } from '../api/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../alert/alert.service";
import * as i2 from "../airtable/airtable.service";
import * as i3 from "../api/api.service";
var InterviewPanelService = /** @class */ (function () {
    function InterviewPanelService(alertService, airtableService, apiService) {
        this.alertService = alertService;
        this.airtableService = airtableService;
        this.apiService = apiService;
        this.openPanelSubject = new Subject();
        this.open$ = this.openPanelSubject.asObservable();
    }
    InterviewPanelService.prototype.openPanel = function (panel) {
        this.openPanelSubject.next(panel);
    };
    InterviewPanelService.prototype.closePanel = function (panel) {
        panel.open = false;
        this.openPanelSubject.next(panel);
    };
    InterviewPanelService.prototype.formatDates = function (slots) {
        var formattedSlots = slots.filter(function (slot) { return (slot.date && slot.time); })
            .map(function (slot, i) {
            if (slot.time[0] === '0') {
                var time = slot.time.replace(/^0+/, '');
                slot.time = time.slice(0, 1) + ":" + time.slice(1);
            }
            else {
                slot.time = slot.time.slice(0, 2) + ":" + slot.time.slice(2);
            }
            return i + 1 + ". " + slot.date.month + "/" + slot.date.day + "/" + slot.date.year + " at " + slot.time + " " + slot.meridiem;
        });
        formattedSlots.push(formattedSlots.length + 1 + ". None of these work for me.");
        return formattedSlots;
    };
    InterviewPanelService.prototype.formatInterviewData = function (request) {
        request.slots = this.formatDates(request.slots);
        var datetimeKeyTwo = request.slots[1] && request.slots[1].includes('None') ? 'datetime_none' : 'datetime_two';
        var datetimeKeyThree = request.slots[2] && request.slots[2].includes('None') ? 'datetime_none' : 'datetime_three';
        var datetimeKeyFour = request.slots[3] && request.slots[3].includes('None') ? 'datetime_none' : 'datetime_four';
        var candFirstName = request.interviewee.fields['Applicant First Name'] ? request.interviewee.fields['Applicant First Name'][0] : null;
        var candLastName = request.interviewee.fields['Applicant Last Name'] ? request.interviewee.fields['Applicant Last Name'][0] : null;
        var candPhone = request.interviewee.fields['Applicant Phone'] ? request.interviewee.fields['Applicant Phone'][0] : null;
        var candEmail = request.interviewee.fields['Applicant Email'] ? request.interviewee.fields['Applicant Email'][0] : null;
        var jobTitle = request.interviewee.fields['Position Job Title'] ? request.interviewee.fields['Position Job Title'][0] : null;
        return _a = {
                message: request.message || null,
                datetime_one: request.slots[0] || null
            },
            _a["" + datetimeKeyTwo] = request.slots[1] || null,
            _a["" + datetimeKeyThree] = request.slots[2] || null,
            _a["" + datetimeKeyFour] = request.slots[3] || null,
            _a.candidate_first_name = candFirstName,
            _a.candidate_last_name = candLastName,
            _a.candidate_phone = "+1" + candPhone,
            _a.candidate_email = candEmail,
            _a.smb_first_name = request.smb.firstName,
            _a.smb_last_name = request.smb.lastName,
            _a.smb_business = request.smb.businessName,
            _a.smb_phone = "+1" + request.smb.phone,
            _a.smb_email = request.smb.email,
            _a.job_title = jobTitle,
            _a;
        var _a;
    };
    InterviewPanelService.prototype.onSchedule = function (panel) {
        var _this = this;
        var applicantId = panel.interviewee.fields['Applicant ID'][0];
        var fields = { 'SMB Interview Request': true };
        var payload = this.formatInterviewData(panel);
        payload.flow = "FW3d9993ccab763ec61f6fdf3903837452";
        this.apiService.post("messages", JSON.stringify(payload))
            .subscribe(function (data) {
            _this.airtableService.patch("Applicant Info", applicantId, fields)
                .then(function (data) {
                if (data.error) {
                    console.log(data.error);
                }
                else {
                    _this.closePanel(panel);
                    panel.interviewee.fields['SMB Interview Request'][0] = true;
                    _this.alertService.showAlert({
                        id: 1,
                        type: 'success',
                        msg: "We've received your request to schedule an interview with this match.\n                Expect an email from our scheduling team within 24 hours."
                    });
                }
            });
        });
    };
    InterviewPanelService.ngInjectableDef = i0.defineInjectable({ factory: function InterviewPanelService_Factory() { return new InterviewPanelService(i0.inject(i1.AlertService), i0.inject(i2.AirtableService), i0.inject(i3.ApiService)); }, token: InterviewPanelService, providedIn: "root" });
    return InterviewPanelService;
}());
export { InterviewPanelService };
