// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do:
// • `ng build --env=stage` then `environment.stage.ts` will be used instead.
// • `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  staging: true,
  AIRBRAKE_CONFIG: {
    id: 193430,
    key: 'c62b77905d4130ffe46a19b5df5e4060',
  },
  AIRTABLE_CONFIG: {
    apiKey: 'keyWIeamY1rcEDzJ7',
    base: 'appAgKMEW1K6A41x3',
    view: 'Grid view'
  },
  APP_VERSION: '4.0.0',
  ASSESSMENT_VERSION: {
    applicant: '1.6',
    smb: '1.6'
  },
  AUTH0_CONFIG: {
    baseURL: 'https://staging.certainhire.com',
    callbackPath: 'login/authenticate',
    clientID: 'yq8BqqaB3djXwlv5P5mbJWT1LFMV2o2D',
    domain: 'certainhire-staging.auth0.com',
    userMetadataURL: 'https://staging.certainhire.com/user_metadata'
  },
  AUTH0_GEN_CONFIG: {
    audience: 'https://rL4KbbmjU8DnB-staging.certainhire.com/api/v2',
    baseURL: 'https://certainhire-staging.auth0.com/',
    cid: '0FpB66sNNtVmf0uuzfNLZQxa13y6vkB3',
    cs: 'vNJdJXonIuZVmwDvo_aOmdRhgd0v1lNEPzNI_mcacdt1_CSwwmMDXFZcI8P1ZG-p',
    domain: 'https://certainhire-staging.auth0.com/oauth/token',
    gt: 'client_credentials'
  },
  FILE_STACK_API_KEY: 'AteI7R9dR5i0QpW90O9UXz',
  MIXPANEL_TOKEN: '0a371528caed2040246fce91628e42f9',
  TRAITIFY_CONFIG: {
    host: 'api-sandbox.traitify.com',
    publicKey: 'af81c12c52cb1fb6550954286e',
    version: 'v1'
  },
  SLICKHIRES_CONFIG: {
    baseUrl: 'https://29hhdej3td.execute-api.us-east-1.amazonaws.com/stage/'
  },
  STRIPE_PUB_KEY: 'pk_test_QAFzbI4NauVNSrhWw9I0QwgI'
};
