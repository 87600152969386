import { Component, OnInit } from '@angular/core';
import { WindowRefService } from '../../shared/providers/window-ref/window-ref.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  _window: Window;

  constructor(
    private windowRefService: WindowRefService
  ) {
    this._window = this.windowRefService.getNativeWindow();
  }

  ngOnInit() {}

  toHome(): void {
    this._window.location.href = 'https://certainhire.com';
  }
}
