import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';

import { CookieService } from 'ngx-cookie-service';
import { WindowRefService } from '../window-ref/window-ref.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class MixpanelService {

  uuid: any;
  mpToken: string;
  _window: any;

  constructor(
    private cookieService: CookieService,
    private windowRefService: WindowRefService
  ) {
    this.uuid = uuid;
    this.mpToken = environment.MIXPANEL_TOKEN;
    this._window = this.windowRefService.getNativeWindow();
    this._window.mixpanel.init(this.mpToken);
  }

  setMpSessionId(): void {
    this._window.sessionStorage.setItem('mp_session_id', uuid());
  }

  getMpSessionId(): string {
    return this._window.sessionStorage.getItem('mp_session_id');
  }

  getDistinctId(): string {
    return JSON.parse(this.cookieService.get(`mp_${this.mpToken}_mixpanel`)).distinct_id;
  }

  trackStep(eventName: string, positionData: any, referrer?: string): void {
    const sessionId = this.getMpSessionId();
    this._window.mixpanel.track(eventName, {
      session_id: sessionId,
      referrer: referrer,
      position_id: positionData ? positionData.id : '',
      position_title: positionData.fields['Position Job Title'],
      business_id: positionData.fields['Business ID'][0],
      business_name: positionData.fields['Business Name'][0],
      candidate_id: positionData.userId || ''
    });
  }

  trackQuestion(num: number): void {
    const sessionId = this.getMpSessionId();
    this._window.mixpanel.track('question_submitted', {
      session_id: sessionId,
      question_position: num
    });
  }
}
