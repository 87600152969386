import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class LoadingService {

  isLoading$: Observable<boolean>;
  private isLoadingSubject = new Subject<any>();

  constructor() {
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  isLoading(obj: any): void {
    this.isLoadingSubject.next(obj);
  }
}
