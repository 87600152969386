import { v4 as uuid } from 'uuid';
import { CookieService } from 'ngx-cookie-service';
import { WindowRefService } from '../window-ref/window-ref.service';
import { environment } from '../../../../environments/environment';
var MixpanelService = /** @class */ (function () {
    function MixpanelService(cookieService, windowRefService) {
        this.cookieService = cookieService;
        this.windowRefService = windowRefService;
        this.uuid = uuid;
        this.mpToken = environment.MIXPANEL_TOKEN;
        this._window = this.windowRefService.getNativeWindow();
        this._window.mixpanel.init(this.mpToken);
    }
    MixpanelService.prototype.setMpSessionId = function () {
        this._window.sessionStorage.setItem('mp_session_id', uuid());
    };
    MixpanelService.prototype.getMpSessionId = function () {
        return this._window.sessionStorage.getItem('mp_session_id');
    };
    MixpanelService.prototype.getDistinctId = function () {
        return JSON.parse(this.cookieService.get("mp_" + this.mpToken + "_mixpanel")).distinct_id;
    };
    MixpanelService.prototype.trackStep = function (eventName, positionData, referrer) {
        var sessionId = this.getMpSessionId();
        this._window.mixpanel.track(eventName, {
            session_id: sessionId,
            referrer: referrer,
            position_id: positionData ? positionData.id : '',
            position_title: positionData.fields['Position Job Title'],
            business_id: positionData.fields['Business ID'][0],
            business_name: positionData.fields['Business Name'][0],
            candidate_id: positionData.userId || ''
        });
    };
    MixpanelService.prototype.trackQuestion = function (num) {
        var sessionId = this.getMpSessionId();
        this._window.mixpanel.track('question_submitted', {
            session_id: sessionId,
            question_position: num
        });
    };
    return MixpanelService;
}());
export { MixpanelService };
