import { Router } from '@angular/router';
import { AirtableService } from '../../../../shared/providers/airtable/airtable.service';
import { environment } from '../../../../../environments/environment';
var JobApplicationResolve = /** @class */ (function () {
    function JobApplicationResolve(airtableService, router) {
        this.airtableService = airtableService;
        this.router = router;
    }
    JobApplicationResolve.prototype.resolve = function (route, state) {
        var _this = this;
        if (!route.queryParams.job_id) {
            window.location.href = 'https://apply.certainhire.com';
        }
        else {
            return this.airtableService.get("positions", route.queryParams.job_id, '', null)
                .then(function (data) {
                if (data.error) {
                    if (data.error === 'NOT_FOUND' || data.error.type === 'MODEL_ID_NOT_FOUND') {
                        _this.router.navigate(['/404']);
                    }
                    else if (!environment.production) {
                        console.log(data.error);
                    }
                }
                else {
                    return data;
                }
            });
        }
    };
    return JobApplicationResolve;
}());
export { JobApplicationResolve };
