import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../../providers/auth/auth.service';
import { WindowRefService } from '../../providers/window-ref/window-ref.service';

@Injectable()
export class AuthGuard implements CanActivate {

  _window: Window;

  constructor(
    private authService: AuthService,
    private router: Router,
    private windowRefService: WindowRefService
  ) {
    this._window = this.windowRefService.getNativeWindow();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const isLoggedIn = this.authService.isAuthenticated();

    if (!isLoggedIn) {
      if (this.authService.getSession()) {
        this._window.localStorage.removeItem('sh_u');
      }
      this.router.navigate(['/login']);
    }
    return isLoggedIn;
  }
}
