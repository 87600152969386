import { OnInit } from '@angular/core';
import { WindowRefService } from '../../shared/providers/window-ref/window-ref.service';
var NotFoundComponent = /** @class */ (function () {
    function NotFoundComponent(windowRefService) {
        this.windowRefService = windowRefService;
        this._window = this.windowRefService.getNativeWindow();
    }
    NotFoundComponent.prototype.ngOnInit = function () { };
    NotFoundComponent.prototype.toHome = function () {
        this._window.location.href = 'https://certainhire.com';
    };
    return NotFoundComponent;
}());
export { NotFoundComponent };
