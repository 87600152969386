import { WindowRefService } from '../window-ref/window-ref.service';
import { environment } from '../../../../environments/environment';
var TraitifyService = /** @class */ (function () {
    function TraitifyService(windowRefService) {
        this.windowRefService = windowRefService;
        this._window = this.windowRefService.getNativeWindow();
        this.traitify = this._window.Traitify;
        this.traitify.setPublicKey(environment.TRAITIFY_CONFIG.publicKey);
        this.traitify.setHost(environment.TRAITIFY_CONFIG.host);
        this.traitify.setVersion(environment.TRAITIFY_CONFIG.version);
    }
    // TODO: Need to grab assessment id prior to calls
    TraitifyService.prototype.getDecks = function (id) {
        return this.traitify
            .getDecks(id)
            .then(function (data) {
            console.log(data);
        });
    };
    TraitifyService.prototype.getSlides = function (id) {
        return this.traitify
            .getSlides(id)
            .then(function (data) {
            console.log(data);
        });
    };
    TraitifyService.prototype.addSlide = function (id) {
        return this.traitify
            .addSlide(id)
            .then(function (data) {
            console.log(data);
        });
    };
    TraitifyService.prototype.getPersonalityTraits = function (id) {
        return this.traitify
            .getPersonalityTraits(id)
            .then(function (data) {
            console.log(data);
        });
    };
    TraitifyService.prototype.getPersonalityTypes = function (id) {
        return this.traitify
            .getPersonalityTypes(id)
            .then(function (data) {
            console.log(data);
        });
    };
    TraitifyService.prototype.getPersonalityBlend = function (id) {
        return this.traitify
            .get("/assessments/" + id + "?data=blend&image_pack=linear")
            .then(function (data) {
            console.log(data);
        });
    };
    TraitifyService.prototype.getSlidesByUrl = function (id) {
        return this.traitify
            .get("/assessments/" + id + "/slides")
            .then(function (data) {
            console.log(data);
        });
    };
    return TraitifyService;
}());
export { TraitifyService };
