import { WindowRefService } from '../../../shared/providers/window-ref/window-ref.service';
var OnboardingGuard = /** @class */ (function () {
    function OnboardingGuard(windowRefService) {
        this.windowRefService = windowRefService;
        this._window = this.windowRefService.getNativeWindow();
    }
    OnboardingGuard.prototype.canActivateChild = function () {
        return true;
    };
    OnboardingGuard.prototype.canDeactivate = function (component) {
        if (component.activeStep.number === 1 && component.onboarding.touched && !component.stepOneComplete) {
            return this._window.confirm('Are you sure? Data will be lost.');
        }
        return true;
    };
    return OnboardingGuard;
}());
export { OnboardingGuard };
