import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { AuthService } from '../../providers/auth/auth.service';
import { HelperService } from '../../providers/helper/helper.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isLoggedIn: boolean;
  isOnboarding: boolean;

  constructor(
    private authService: AuthService,
    private helperService: HelperService,
    private router: Router
  ) {
    this.authService.isLoggedIn$
      .subscribe((event) => this.isLoggedIn = event);
  }

  ngOnInit() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => this.isOnboarding = this.helperService.checkForOnboardingPath());
  }

  toRoot(): string {
    return this.helperService.getRootUrl();
  }

  handleLogout(): void {
    this.authService.logout();
  }
}
