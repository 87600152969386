
import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { map, catchError } from 'rxjs/operators';

import { AirtableService } from '../airtable/airtable.service';
import { LoadingService } from '../loading/loading.service';
import { ToastrService } from 'ngx-toastr';
import { WindowRefService } from '../window-ref/window-ref.service';

@Injectable()
export class AuthService {

  loginError$: Observable<any>;
  private loginErrorSubject = new Subject<any>();

  isLoggedIn$: Observable<boolean>;
  private isLoggedInSubject = new Subject<any>();

  resetRequested$: Observable<boolean>;
  private resetRequestedSubject = new Subject<any>();

  auth0: any;
  _window: Window;

  constructor(
    private airtableService: AirtableService,
    private http: HttpClient,
    private loadingService: LoadingService,
    private router: Router,
    private toastr: ToastrService,
    private windowRefService: WindowRefService
  ) {
    this.isLoggedIn$ = this.isLoggedInSubject.asObservable();
    this.loginError$ = this.loginErrorSubject.asObservable();
    this.resetRequested$ = this.resetRequestedSubject.asObservable();
    this._window = this.windowRefService.getNativeWindow();
    this.auth0 = new auth0.WebAuth({
      clientID: environment.AUTH0_CONFIG.clientID,
      domain: environment.AUTH0_CONFIG.domain,
      responseType: 'token id_token',
      audience: `https://${environment.AUTH0_CONFIG.domain}/userinfo`,
      redirectUri: `${environment.AUTH0_CONFIG.baseURL}/${environment.AUTH0_CONFIG.callbackPath}`,
      scope: 'email'
    });
  }

  signUp(userData: any): any {
    return Promise.resolve(
      this.auth0.signup({
        email: userData.email,
        password: userData.password,
        connection: 'Username-Password-Authentication',
        user_metadata: {
          airtableId: userData.id,
          username: userData.username
        }
      }, ((err) => {
        if (!environment.production && err) {
          console.log(err);
        }
      }))
    );
  }

  login(formData: any): void {
    this.auth0.login({
      email: formData.email,
      password: formData.password,
      realm: 'Username-Password-Authentication'
    }, ((err) => {
      this.loginErrorSubject.next(err);
    }));
  }

  logout(): void {
    this._window.localStorage.removeItem('access_token');
    this._window.localStorage.removeItem('id_token');
    this._window.localStorage.removeItem('expires_at');
    this._window.localStorage.removeItem('sh_u');
    this.auth0.logout();
  }

  handleAuthentication(): void {
    this.loadingService.isLoading({ bool: true, type: 'login' });
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        authResult.userMetadata = {
          airtableId: authResult.idTokenPayload[`${environment.AUTH0_CONFIG.baseURL}/user_metadata`].airtableId
        }
        this.setSession(authResult);
      } else if (err) {
        if (!environment.production) {
          console.log(err);
        }
        this.router.navigate(['/login'])
          .then(() => this.loadingService.isLoading({ bool: false, type: 'login' }));
      }
    });
  }

  setSession(authResult): void {
    this.airtableService.get(`Business Info`, authResult.userMetadata.airtableId)
      .then((data) => {
        const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
        const userData = JSON.stringify(this.formatUserData(data));
        this._window.localStorage.setItem('access_token', authResult.accessToken);
        this._window.localStorage.setItem('id_token', authResult.idToken);
        this._window.localStorage.setItem('expires_at', expiresAt);
        this._window.localStorage.setItem('sh_u', userData);
        this.router.navigate(['/dashboard'])
          .then(() => this.loadingService.isLoading({ bool: false, type: 'login' }));
    });
  }

  setSessionToken(token: string): void {
    this._window.sessionStorage.setItem('seshid', token);
  }

  getSession(): any {
    const user = this._window.localStorage.getItem('sh_u');
    if (user) {
      return JSON.parse(user);
    }
  }

  createCustomerSession(userData: any): any {
    if (userData && userData.fields) {
      const customer = {
        customer: userData.fields.stripe_customer_id ? userData.fields.stripe_customer_id[0] : null
      };
      this._window.localStorage.setItem('sh_u', JSON.stringify(customer));
    }
  }

  updateCustomerSession(userData: any): any {
    const user = this._window.localStorage.getItem('sh_u');
    if (user) {
      const customer = JSON.parse(user);
      customer.card = userData.fields.cards ? userData.fields.cards[0] : null;
      customer.customer = userData.fields.stripe_customer_id ? userData.fields.stripe_customer_id[0] : null;
      customer.orders = userData.fields.orders || null;
      customer.positions = userData.fields['Positions'] || null;
      this._window.localStorage.setItem('sh_u', JSON.stringify(customer));
    }
  }

  isAuthenticated(): boolean {
    const expiresAt = JSON.parse(this._window.localStorage.getItem('expires_at'));
    const isSessionActive = new Date().getTime() < expiresAt;
    this.isLoggedInSubject.next(isSessionActive);
    return isSessionActive;
  }

  resetPassword(formData: any): void {
    this.auth0.changePassword({
      email: formData.email,
      connection: 'Username-Password-Authentication'
    }, () => {
      this.toastr.success(`If the account exists we'll send reset instructions to your email.`);
      this.resetRequestedSubject.next(true);
    });
  }

  formatUserData(userData): any {
    if (userData && userData.fields) {
      return {
        address1: userData.fields['Business Street Address 1'] || null,
        appVersion: userData.fields.app_version || null,
        assessmentId: userData.fields['Business Responses (v1.6)'][0] || null,
        businessName: userData.fields['Business Name'] || null,
        card: userData.fields.cards ? userData.fields.cards[0] : null,
        city: userData.fields['Business City'] || null,
        customer: userData.fields.stripe_customer_id ? userData.fields.stripe_customer_id[0] : null,
        decsription: userData.fields['Business Description'] || null,
        email: userData.fields['Business Email'] || null,
        firstName: userData.fields['Business Contact - First Name'] || null,
        id: userData.id || null,
        industry: userData.fields['Industry'] || null,
        jobs: userData.fields['Positions'] || null,
        lastName: userData.fields['Business Contact - Last Name'] || null,
        orders: userData.fields.orders || null,
        phone: userData.fields['Business Phone Number'] || null,
        positions: userData.fields['Positions'] || null,
        state: userData.fields['Business State'] || null,
        username: userData.fields['Business Username'] || null,
        zipcode: userData.fields['Business Zip Code'] || null,
        schedule: userData.fields.interview_schedule ? userData.fields.interview_schedule[0] : null
      }
    }
  }

  /**
   * Sends a POST request to the API
   *
   * @param {string} uri The resource uri
   * @param {any} [data=null] The payload object
   * @returns {*} Server response data
   * @memberof ApiService
   */
  post(uri: string, data=null): any {
    return this.call({ uri: uri, method: 'POST' }, data);
  }

  /**
   * Generates headers for server requests
   *
   * @returns {*} HttpHeaders containing a header object with Authorization
   * @memberof ApiService
   */
  getHeaders(): any {
    const headerObj = {
      'Content-Type': 'application/json'
    };

    return new HttpHeaders(headerObj);
  }

  /**
   * Makes a call to the server with provided options and payload
   *
   * @param {*} opts An object containing uri and request method
   * @param {*} data An object containing the payload
   * @returns {Observable<any>} Server response (success or error) data
   * @memberof ApiService
   */
  call(opts: any, body: any): Observable<any> {
    const url = `${environment.AUTH0_GEN_CONFIG.baseURL + opts.uri}`;
    const headers = this.getHeaders();
    const methodCall = {
      POST: this.http.post(url, body ? body : null, { headers: headers })
    };

    return methodCall[opts.method]
      .pipe(
        map((res: HttpResponse<any>) => res),
        catchError((err: any) => observableThrowError(err.error))
      );
  }
}
