import { Subject } from 'rxjs';
var AlertService = /** @class */ (function () {
    function AlertService() {
        this.alertSubject = new Subject();
        this.alert$ = this.alertSubject.asObservable();
    }
    AlertService.prototype.showAlert = function (alert) {
        this.alertSubject.next(alert);
    };
    return AlertService;
}());
export { AlertService };
