import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { LoadingService } from '../../providers/loading/loading.service';
import { WindowRefService } from '../../providers/window-ref/window-ref.service';

@Injectable()
export class StorageGuard implements CanActivate {

  _window: any;

  constructor(
    private loadingService: LoadingService,
    private router: Router,
    private windowRefService: WindowRefService
  ) {
    this._window = this.windowRefService.getNativeWindow();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.detectPrivateMode((isPrivate) => {
      if (isPrivate) {
        this.loadingService.isLoading({ bool: isPrivate, type: 'storage' });
        this.router.navigate(['/']);
      }
    });

    return true;
  }

  retry(isDone, next) {
    let currentTrial = 0;
    let isTimeout = false;
    const maxRetry = 50;

    const id = this._window.setInterval(() => {
      if (isDone()) {
        this._window.clearInterval(id);
        next(isTimeout);
      }

      if (currentTrial++ > maxRetry) {
        this._window.clearInterval(id);
        isTimeout = true;
        next(isTimeout);
      }
    }, 10);
  }

  isIE10OrLater(userAgent) {
    const ua = userAgent.toLowerCase();

    if (ua.indexOf('msie') === 0 && ua.indexOf('trident') === 0) {
      return false;
    }

    const match = /(?:msie|rv:)\s?([\d\.]+)/.exec(ua);

    if (match && parseInt(match[1], 10) >= 10) {
      return true;
    }

    return false;
  }

  detectPrivateMode(callback) {
    let isPrivate;

    if (this._window.webkitRequestFileSystem) {
      this._window.webkitRequestFileSystem(this._window.TEMPORARY, 1, () => {
        isPrivate = false;
      }, (err) => {
        isPrivate = true;
      });
    } else if (this._window.indexedDB && /Firefox/.test(this._window.navigator.userAgent)) {
      let db;

      try {
        db = this._window.indexedDB.open('test');
      } catch (e) {
        isPrivate = true;
      }

      if (typeof isPrivate === 'undefined') {
        this.retry(() => {
          return db.readyState === 'done' ? true : false;
        }, (isTimeout) => {
          if (!isTimeout) {
            isPrivate = db.result ? false : true;
          }
        });
      }
    } else if (this.isIE10OrLater(this._window.navigator.userAgent)) {
      isPrivate = false;

      try {
        if (!this._window.indexedDB) {
          isPrivate = true;
        }
      } catch (e) {
        isPrivate = true;
      }
    } else if (this._window.localStorage && /Safari/.test(this._window.navigator.userAgent)) {
      try {
        this._window.localStorage.setItem('test', 1);
      } catch (e) {
        isPrivate = true;
      }

      if (typeof isPrivate === 'undefined') {
        isPrivate = false;
        this._window.localStorage.removeItem('test');
      }
    }

    this.retry(() => {
      return typeof isPrivate !== 'undefined' ? true : false;
    }, (isTimeout) => {
      callback(isPrivate);
    });
  }
}
