import { environment } from '../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes, ActivatedRoute, Params } from '@angular/router';
import { NgxStripeModule } from 'ngx-stripe';
import { NouisliderModule } from 'ng2-nouislider';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { DragulaModule } from 'ng2-dragula';
import { NgxMaskModule } from 'ngx-mask';

import { AlertService } from './shared/providers/alert/alert.service';
import { ApiService } from './shared/providers/api/api.service';
import { AirbrakeErrorHandler } from './shared/providers/airbrake/airbrake-error-handler';
import { AirtableService } from './shared/providers/airtable/airtable.service';
import { AssessmentService } from './shared/providers/assessment/assessment.service';
import { AuthService } from './shared/providers/auth/auth.service';
import { FilestackService } from './shared/providers/filestack/filestack.service';
import { HelperService } from './shared/providers/helper/helper.service';
import { InterviewPanelService } from './shared/providers/interview-panel/interview-panel.service';
import { InterviewService } from './shared/providers/interview/interview.service';
import { JobService } from './modules/jobs/providers/job/job.service';
import { LoadingService } from './shared/providers/loading/loading.service';
import { MixpanelService } from './shared/providers/mixpanel/mixpanel.service';
import { OnboardingService } from './modules/onboarding/providers/onboarding.service';
import { TraitifyService } from './shared/providers/traitify/traitify.service';
import { WindowRefService } from './shared/providers/window-ref/window-ref.service';

import { JobApplicationResolve } from './modules/jobs/components/job-application/job-application.resolve';
import { OnboardingResolve } from './modules/onboarding/components/onboarding/onboarding.resolve';

import { AuthGuard } from './shared/guards/auth/auth.guard';
import { DashboardGuard } from './modules/dashboard/guards/dashboard.guard';
import { NewJobPostGuard } from './modules/dashboard/guards/new-job-post.guard';
import { OnboardingGuard } from './modules/onboarding/guards/onboarding.guard';
import { StorageGuard } from './shared/guards/storage/storage.guard';

import { AngularGooglePlaceModule } from 'angular-google-place';
import { AppComponent } from './app.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { LandingPageComponent } from './pages/landing/landing.component';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';


export const ROUTES: Routes = [
  { path: '',
    component: LandingPageComponent,
    pathMatch: 'full',
    canActivate: [StorageGuard]
  },
  {
    path: 'dashboard',
    loadChildren: './modules/dashboard/dashboard.module#DashboardModule',
    canActivate: [StorageGuard]
  },
  {
    path: 'jobs',
    loadChildren: './modules/jobs/jobs.module#JobsModule',
    canActivate: [StorageGuard]
  },
  {
    path: 'login',
    loadChildren: './modules/auth/auth.module#AuthModule',
    canActivate: [StorageGuard]
  },
  {
    path: 'onboarding',
    loadChildren: './modules/onboarding/onboarding.module#OnboardingModule',
    canActivate: [StorageGuard]
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    LandingPageComponent,
    LoadingComponent,
    NotFoundComponent
  ],
  imports: [
    AngularGooglePlaceModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    DragulaModule,
    FormsModule,
    HttpClientModule,
    NgbModule.forRoot(),
    NgxStripeModule.forRoot(environment.STRIPE_PUB_KEY),
    NgxMaskModule.forRoot(),
    NouisliderModule,
    ReactiveFormsModule,
    RouterModule.forRoot(ROUTES),
    ToastrModule.forRoot({
      tapToDismiss: false,
      timeOut: 3000,
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
    })
  ],
  entryComponents: [],
  providers: [
    {
      provide: ErrorHandler,
      useClass: AirbrakeErrorHandler
    },
    AlertService,
    ApiService,
    AirtableService,
    AssessmentService,
    AuthGuard,
    AuthService,
    DashboardGuard,
    FilestackService,
    HelperService,
    InterviewPanelService,
    InterviewService,
    JobService,
    LoadingService,
    MixpanelService,
    CookieService,
    NewJobPostGuard,
    OnboardingService,
    OnboardingGuard,
    StorageGuard,
    TraitifyService,
    WindowRefService,
    JobApplicationResolve,
    OnboardingResolve
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
