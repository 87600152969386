import { Subject } from 'rxjs';
var LoadingService = /** @class */ (function () {
    function LoadingService() {
        this.isLoadingSubject = new Subject();
        this.isLoading$ = this.isLoadingSubject.asObservable();
    }
    LoadingService.prototype.isLoading = function (obj) {
        this.isLoadingSubject.next(obj);
    };
    return LoadingService;
}());
export { LoadingService };
