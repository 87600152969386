import { Injectable } from '@angular/core';
import { CanActivateChild, CanDeactivate } from '@angular/router';

import { OnboardingComponent } from '../components/onboarding/onboarding.component';
import { WindowRefService } from '../../../shared/providers/window-ref/window-ref.service';

@Injectable()
export class OnboardingGuard implements CanActivateChild, CanDeactivate<OnboardingComponent> {

  _window: Window;

  constructor(
    private windowRefService: WindowRefService
  ) {
    this._window = this.windowRefService.getNativeWindow();
  }

  canActivateChild() {
    return true;
  }

  canDeactivate(component: OnboardingComponent) {
    if (component.activeStep.number === 1 && component.onboarding.touched && !component.stepOneComplete) {
      return this._window.confirm('Are you sure? Data will be lost.');
    }
    return true;
  }
}
