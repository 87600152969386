import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { HelperService } from '../../providers/helper/helper.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  isOnboarding: boolean;

  constructor(
    private helperService: HelperService,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => this.isOnboarding = this.helperService.checkForOnboardingPath());
  }

  toRoot(): string {
    return this.helperService.getRootUrl();
  }
}
