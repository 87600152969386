import { ErrorHandler } from '@angular/core';
import * as airbrakeJs from 'airbrake-js';
import { environment } from '../../../../environments/environment';

export class AirbrakeErrorHandler implements ErrorHandler {

  airbrake: any;

  constructor() {
    if (environment.production || environment.staging) {
      this.airbrake = new airbrakeJs({
        projectId: environment.AIRBRAKE_CONFIG.id,
        projectKey: environment.AIRBRAKE_CONFIG.key
      });

      this.airbrake.addFilter((notice) => {
        notice.context.environment = environment.staging ? 'staging' : 'production';
        return notice;
      });
    }
  }

  handleError(error: any): void {
    if (environment.production || environment.staging) {
      this.airbrake.notify(error);
    }
  }
}
