import { throwError as observableThrowError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { WindowRefService } from '../window-ref/window-ref.service';
import { map, catchError } from 'rxjs/operators';
var ApiService = /** @class */ (function () {
    function ApiService(http, windowRefService) {
        this.http = http;
        this.windowRefService = windowRefService;
        this._window = window;
        this.apiBaseUrl = environment.SLICKHIRES_CONFIG.baseUrl;
        this._window = this.windowRefService.getNativeWindow();
    }
    /**
     * Sends a GET request to the API
     *
     * @param {string} uri The resource uri
     * @param {any} [data=null] The payload object
     * @returns {*} Server response data
     * @memberof ApiService
     */
    ApiService.prototype.get = function (uri, data) {
        if (data === void 0) { data = null; }
        return this.call({ uri: uri, method: 'GET' }, data);
    };
    /**
     * Sends a POST request to the API
     *
     * @param {string} uri The resource uri
     * @param {any} [data=null] The payload object
     * @returns {*} Server response data
     * @memberof ApiService
     */
    ApiService.prototype.post = function (uri, data) {
        if (data === void 0) { data = null; }
        return this.call({ uri: uri, method: 'POST' }, data);
    };
    /**
     * Sends a PUT request to the API
     *
     * @param {string} uri The resource uri
     * @param {any} [data=null] The payload object
     * @returns {*} Server response data
     * @memberof ApiService
     */
    ApiService.prototype.put = function (uri, data) {
        if (data === void 0) { data = null; }
        return this.call({ uri: uri, method: 'PUT' }, data);
    };
    /**
     * Sends a PATCH request to the API
     *
     * @param {string} uri The resource uri
     * @param {any} [data=null] The payload object
     * @returns {*} Server response data
     * @memberof ApiService
     */
    ApiService.prototype.patch = function (uri, data) {
        if (data === void 0) { data = null; }
        return this.call({ uri: uri, method: 'PATCH' }, data);
    };
    /**
     * Sends a DELETE request to the API
     *
     * @param {string} uri The resource uri
     * @param {any} [data=null] The payload object
     * @returns {*} Server response data
     * @memberof ApiService
     */
    ApiService.prototype.delete = function (uri, data) {
        if (data === void 0) { data = null; }
        return this.call({ uri: uri, method: 'DELETE' }, data);
    };
    /**
     * Generates headers for server requests
     *
     * @returns {*} HttpHeaders containing a header object with Authorization
     * @memberof ApiService
     */
    ApiService.prototype.getHeaders = function (method) {
        var headerObj = {
            'Authorization': "Bearer " + this.getSessionToken(),
            'Content-Type': method === 'POST' ? 'application/x-www-form-urlencoded' : 'application/json'
        };
        return new HttpHeaders(headerObj);
    };
    /**
     * Makes a call to the server with provided options and payload
     *
     * @param {*} opts An object containing uri and request method
     * @param {*} data An object containing the payload
     * @returns {Observable<any>} Server response (success or error) data
     * @memberof ApiService
     */
    ApiService.prototype.call = function (opts, body) {
        var _this = this;
        var url = "" + (this.apiBaseUrl + opts.uri);
        var headers = this.getHeaders(opts.method);
        var methodCall = {
            GET: this.http.get(url, { headers: headers }),
            POST: this.http.post(url, body ? body : null, { headers: headers }),
            PUT: this.http.put(url, body ? body : {}, { headers: headers }),
            PATCH: this.http.patch(url, body ? body : {}, { headers: headers }),
            DELETE: this.http.delete(url, { headers: headers })
        };
        return methodCall[opts.method]
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            _this.handleErrors(err);
            return observableThrowError(err.error);
        }));
    };
    /**
     * Server error handler
     *
     * @param {*} err The error object
     * @memberof ApiService
     */
    ApiService.prototype.handleErrors = function (err) {
        var defaultServerError = "Server Error. Wait a few minutes and try again.";
        var defaultErrorMsg = "Something went wrong. Try again or contact support.";
        if (!environment.production) {
            console.log(err);
        }
        switch (err.status) {
            // Bad Request
            case 400:
                this.renderErrorMsg(defaultErrorMsg);
                break;
            // Unauthroized
            case 401:
                this.renderErrorMsg("Correct authentication required to access this resource.");
                break;
            // Not found
            case 404:
                this.renderErrorMsg("Could not find the requested resouce.");
                break;
            // Request Entity Too Large
            case 413:
                this.renderErrorMsg(defaultErrorMsg);
                break;
            // Invalid Request
            case 422:
                this.renderErrorMsg(defaultErrorMsg);
                break;
            // Internal Server Error
            case 500:
                this.renderErrorMsg(defaultServerError);
                break;
            // Bad Gateway
            case 502:
                this.renderErrorMsg(defaultServerError);
                break;
            // Service Unavailable
            case 503:
                this.renderErrorMsg(defaultServerError);
                break;
            default:
                break;
        }
    };
    /**
     * Renders error messages
     *
     * @param {string} msg The message to render
     * @memberof ApiService
     */
    ApiService.prototype.renderErrorMsg = function (msg) {
        // TODO: Toast the thing...
    };
    ApiService.prototype.formatCustomerData = function (token) {
        return {
            business_id: [this._window.sessionStorage.getItem('sh_smbid') || JSON.parse(this._window.localStorage.getItem('sh_u')).id],
            stripe_token: token.id,
            stripe_source: token.card.id
        };
    };
    ApiService.prototype.formatCardData = function (token) {
        return {
            stripe_token: token.id,
            stripe_source: token.card.id
        };
    };
    ApiService.prototype.getSessionToken = function () {
        return this._window.sessionStorage.getItem('seshid');
    };
    return ApiService;
}());
export { ApiService };
