import * as airbrakeJs from 'airbrake-js';
import { environment } from '../../../../environments/environment';
var AirbrakeErrorHandler = /** @class */ (function () {
    function AirbrakeErrorHandler() {
        if (environment.production || environment.staging) {
            this.airbrake = new airbrakeJs({
                projectId: environment.AIRBRAKE_CONFIG.id,
                projectKey: environment.AIRBRAKE_CONFIG.key
            });
            this.airbrake.addFilter(function (notice) {
                notice.context.environment = environment.staging ? 'staging' : 'production';
                return notice;
            });
        }
    }
    AirbrakeErrorHandler.prototype.handleError = function (error) {
        if (environment.production || environment.staging) {
            this.airbrake.notify(error);
        }
    };
    return AirbrakeErrorHandler;
}());
export { AirbrakeErrorHandler };
