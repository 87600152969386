import { environment } from '../../../../environments/environment';
import { Injectable, ErrorHandler } from '@angular/core';
import * as filestack from 'filestack-js';

@Injectable()
export class FilestackService {

  apikey: string;
  client: any;

  constructor(
    private errHandler: ErrorHandler
  ) {
    this.apikey = environment.FILE_STACK_API_KEY;
    this.client = filestack.default.init(this.apikey);
  }

  upload() {
    return this.client.pick({
      maxFiles: 1,
      uploadInBackground: false,
      accept: ['.pdf', '.doc', '.docx', '.odt'],
      fromSources: ['local_file_system'],
      storeTo: {
        location: 's3',
        region: 'us-east-2',
        container: 'certainhire.uploads',
        access: 'private'
      }
    })
    .then((res) => {
      if (res.filesUploaded) {
        return res.filesUploaded[0];
      } else {
        if (environment.production) {
          this.errHandler.handleError(JSON.stringify(res.filesFailed));
        }
      }
    });
  }
}
