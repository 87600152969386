import { Router } from '@angular/router';
import { LoadingService } from '../../providers/loading/loading.service';
import { WindowRefService } from '../../providers/window-ref/window-ref.service';
var StorageGuard = /** @class */ (function () {
    function StorageGuard(loadingService, router, windowRefService) {
        this.loadingService = loadingService;
        this.router = router;
        this.windowRefService = windowRefService;
        this._window = this.windowRefService.getNativeWindow();
    }
    StorageGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        this.detectPrivateMode(function (isPrivate) {
            if (isPrivate) {
                _this.loadingService.isLoading({ bool: isPrivate, type: 'storage' });
                _this.router.navigate(['/']);
            }
        });
        return true;
    };
    StorageGuard.prototype.retry = function (isDone, next) {
        var _this = this;
        var currentTrial = 0;
        var isTimeout = false;
        var maxRetry = 50;
        var id = this._window.setInterval(function () {
            if (isDone()) {
                _this._window.clearInterval(id);
                next(isTimeout);
            }
            if (currentTrial++ > maxRetry) {
                _this._window.clearInterval(id);
                isTimeout = true;
                next(isTimeout);
            }
        }, 10);
    };
    StorageGuard.prototype.isIE10OrLater = function (userAgent) {
        var ua = userAgent.toLowerCase();
        if (ua.indexOf('msie') === 0 && ua.indexOf('trident') === 0) {
            return false;
        }
        var match = /(?:msie|rv:)\s?([\d\.]+)/.exec(ua);
        if (match && parseInt(match[1], 10) >= 10) {
            return true;
        }
        return false;
    };
    StorageGuard.prototype.detectPrivateMode = function (callback) {
        var isPrivate;
        if (this._window.webkitRequestFileSystem) {
            this._window.webkitRequestFileSystem(this._window.TEMPORARY, 1, function () {
                isPrivate = false;
            }, function (err) {
                isPrivate = true;
            });
        }
        else if (this._window.indexedDB && /Firefox/.test(this._window.navigator.userAgent)) {
            var db_1;
            try {
                db_1 = this._window.indexedDB.open('test');
            }
            catch (e) {
                isPrivate = true;
            }
            if (typeof isPrivate === 'undefined') {
                this.retry(function () {
                    return db_1.readyState === 'done' ? true : false;
                }, function (isTimeout) {
                    if (!isTimeout) {
                        isPrivate = db_1.result ? false : true;
                    }
                });
            }
        }
        else if (this.isIE10OrLater(this._window.navigator.userAgent)) {
            isPrivate = false;
            try {
                if (!this._window.indexedDB) {
                    isPrivate = true;
                }
            }
            catch (e) {
                isPrivate = true;
            }
        }
        else if (this._window.localStorage && /Safari/.test(this._window.navigator.userAgent)) {
            try {
                this._window.localStorage.setItem('test', 1);
            }
            catch (e) {
                isPrivate = true;
            }
            if (typeof isPrivate === 'undefined') {
                isPrivate = false;
                this._window.localStorage.removeItem('test');
            }
        }
        this.retry(function () {
            return typeof isPrivate !== 'undefined' ? true : false;
        }, function (isTimeout) {
            callback(isPrivate);
        });
    };
    return StorageGuard;
}());
export { StorageGuard };
